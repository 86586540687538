import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Result, Button } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

import './Forbidden.less'

const ForbiddenPage = () => {
  const navigate = useNavigate()
  const { userId } = useAuth()
  return (
    <Layout className='forbidden-page'>
      <Layout.Content>
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you not allow to access this page.'
          extra={
            <Button onClick={() => navigate((userId) ? '/admin' : '/login')} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} type='primary'><HomeOutlined />Home</Button>
          }
        />
      </Layout.Content>
    </Layout>
  )
}

export default ForbiddenPage
